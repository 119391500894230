.fa-camera {
  cursor: pointer;
}

.order-detail {
  .alert {
    border-radius: 0;
  }
}

.editable-buttons {
  display: flex;
  flex-direction: row;
}

.OrderDetails__PaymentMethod {
}

.OrderDetails__PaymentMethod__Icon {
  max-width: 16px;
  min-width: 16px;
}
