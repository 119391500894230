
.OrderItemsContainer {
  border: 1px solid silver;
  padding: 5px;
}

.OrderItem {
  margin-bottom: 5px;
}

.OrderItem > div {
  margin-right: 5px;
}
