// flex helpers

.flex {
  flex: 1;
  box-sizing: border-box;
}

@mixin flex($num) {
  flex: $num;
  box-sizing: border-box;
}

@for $i from 1 through 12 {
  .flex-#{$i} {
    @include flex($i);
  }
}

.flex-100 {
  flex: 1 1 100%;

  max-width: 100%;
  max-height: 100%;

  box-sizing: border-box;
}

.space-between {
  justify-content: space-between;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.layout-align-start-stretch {
  align-items: stretch;
}

.layout-align-start,
.layout-align-start-start,
.layout-align-start-center,
.layout-align-start-end,
.layout-align-start-stretch {
  justify-content: flex-start;
}

//.layout-align-end,
.layout-align-end-start,
.layout-align-end-center,
.layout-align-end-end,
.layout-align-end-stretch {
  justify-content: flex-end;
}

.layout-align-start-center,
.layout-align-center-center,
.layout-align-end-center,
.layout-align-space-between-center,
.layout-align-space-around-center {
  align-items: center;
  align-content: center;
  max-width: 100%;
}

.layout-align-end-center {
  //-webkit-align-items: flex-end;
  //align-items: flex-end;
}

.layout-align-end {
  align-items: flex-end;
}

.layout-align-start-center {
  align-items: center;
  align-content: center;
  max-width: 100%;
}

.layout-align-center-center {
  justify-content: center;
}

.layout,
.layout-column,
.layout-row {
  box-sizing: border-box;
  display: flex;
}

.layout-column {
  flex-direction: column;
}

.layout-row {
  flex-direction: row;
}

.layout-wrap {
  flex-wrap: wrap;
}

.layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.layout-column > .flex {
  min-height: 0;
}

.big-column {
  flex: 2 0 0;

  //@media (min-zoom: 1.0) {}
  &--sticky {
    @media (min-width: 1025px) {
      position: sticky;
      top: 12px;
    }
  }
}

.small-column {
  flex: 1 0 0;
}

.top-margin-20 {
  margin-top: 20px;
}

.gutter-5 {
  & > div {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

// fake bs

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.align-items-center {
  align-items: center;
}

//.mb-3 {
//  margin-bottom: 1.3rem;
//}
//.mr-3 {
//  margin-right: 1.3rem;
//}

@for $key from 1 through 5 {
  $value: #{$key / 10}rem;

  .p-#{$key} {
    padding: $value;
  }
  .pt-#{$key} {
    padding-top: $value;
  }
  .pr-#{$key} {
    padding-right: $value;
  }
  .pb-#{$key} {
    padding-bottom: $value;
  }
  .pl-#{$key} {
    padding-left: $value;
  }

  .m-#{$key} {
    margin: $value;
  }
  .mt-#{$key} {
    margin-top: $value;
  }
  .mr-#{$key} {
    margin-right: $value;
  }
  .mb-#{$key} {
    margin-bottom: $value;
  }
  .ml-#{$key} {
    margin-left: $value;
  }
}

@for $key from 1 through 12 {
  .col-#{$key} {
    @extend .col-xs-#{$key};
  }
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.flex-1 {
}
