.separator {
  height: 1px;
  background-color: silver;
}

.Anonymous {
  margin-top: 10px;
  padding: 5px;
  display: block;
  color: black;
  background-color: #ffaea9;
  border: 1px solid #d38c87;
}

.Wrapper {
}

.OrderDetail {
  //min-width: 425px;
  //min-width: 373px;
  min-width: 500px;
  margin-bottom: 0;
  padding: 5px;

  .alert {
    border-radius: 0;
  }
}

.OrderDetail > div {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.OrderDetail h3 {
  margin: 0;
  margin-bottom: 5px;

  &.title {
    display: inline-block;
  }
}

.Red {
  color: red;
}

.BoldRed {
  font-weight: bold;
  color: red;
}

.BoldGreen {
  font-weight: bold;
  color: green;
}

.CommentText {
  width: 100%;
  max-width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all; // fallback due to the following
  word-break: break-word; // not supported on everything
}

.CardText {
  background-color: #f7f7f7;
  padding: 5px;
  border: 1px dotted silver;

  //
  width: 100%;
  max-width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all; // fallback due to the following
  word-break: break-word; // not supported on everything

  margin-bottom: 5px;
}

.deliveryTimeframe {
  font-weight: bold;
  font-size: 18px;
}

.extraServicePhoto {
  @extend .deliveryTimeframe;
  cursor: pointer;
}

.deliveryTransport {
  font-weight: bold;
  font-size: 24px;
}

.OrderDetail__OrderInformation__HasNotes {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.3rem;
  padding: 6px;
  background-color: #feffb7;
  border: 1px solid #e9d060;
}

.no-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__button {
    display: none;
  }
}

.OrderDetail__CardDetails__HasBcCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.3rem;
  padding: 6px;
  background-color: #feffb7;
  border: 1px solid #e9d060;
}
