
.OrderListWrapper {
  //position: sticky;
  //top: 12px;
}

.OrderListPaper {
  margin-bottom: 0;
  padding: 0;
}
.MourningOrder {
  font-size: 1.8em;
  height: 16px;
  margin-top: -8px;
  color: black !important;
}
