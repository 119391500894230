.container {
  color: gray;
  vertical-align: middle;
  line-height: 2em;

  & > i {
    display: inline-block;
    vertical-align: middle;
  }
  & > span {
    line-height: 2em; // todo do not use fa-3x and supply font per item
    display: inline-block;
    vertical-align: middle;
    font-size: 1.1em;
  }
}
