.container {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  display: flex;
  width: 256px;

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  z-index: 11;

  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  background-color: white;
}

.nav {
  flex: 1 1 auto;
  padding-bottom: 32px;
  transform: translate3d(0, 0, 0);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.navGroup {
  flex-shrink: 0;
  position: relative;
  margin: 0;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: justify;
  white-space: nowrap;
  box-shadow: none;
  padding: 4px 0;
  transition: box-shadow 0.3s ease, padding 0.3s ease, width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.navGroupRoot {
  background: #fafafa;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12) inset !important;
  position: relative;
  padding: 12px 0;
  margin-bottom: 12px;
}

/// nav-item
.navItem {
  margin: 0;
  height: 32px;
  min-width: 0;
  overflow: visible;
  width: 100%;
  border-radius: 0;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: start;
  text-decoration: none;
  text-transform: none;
}

.navItemLockUp {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2px;
  position: relative;
}

.mdButton {
  margin: 0;
  height: 32px;
  min-width: 0;
  overflow: visible;
  width: 100%;
  border-radius: 0;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: start;
  text-decoration: none;
  text-transform: none;

  padding: 6px 24px;

  display: inline-block;

  vertical-align: baseline;
}

.navIcon {
  font-size: 20px;
}

.iconUpdated {
  font-size: 12px;
  color: #ee0000;
  width: 12px;
  height: 12px;
  position: absolute;
  top: -2px;
  left: 13px;
}

.navExpando {
  display: block;
  flex: 0 0 auto;
  height: 44px;
  padding: 0;
  margin: 0;
  min-width: 0;
  background: #fafafa;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;

  .materialIcons {
    position: absolute;
    top: 10px;
    right: 22px;
    width: 24px;
    height: 24px;
    color: rgba(0, 0, 0, 0.54);
    pointer-events: none;
    transition: transform 0.3s ease;
    font-size: 24px;
  }
}

.displayName {
  padding-left: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 128px;
  opacity: 1;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.activeRoute {
  color: #039be5 !important;
}

.collapsed {
  width: 68px;
  overflow: hidden;

  .displayName {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.spacer {
  margin-top: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
}
