
// margin-left: 20px;

.module-canvas > div > .layout-column {
  //&:last-child {
  //  margin-left: 12px;
  //}
  //&:only-child {
  //  margin-left: 0;
  //}
}

.module-canvas {
  //max-width: 995px;

  .order-detail {
    margin-left: 12px;
  }

}

.table-header {
  & > div {
    padding-top: 10px;
  }
}

@mixin flatten-row () {
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  &.row::after {
    display: initial;
  }
  & > label {
    display: none;
  }
  @include clearfix;
  margin: 0;

  @content;

  //@include media-breakpoint-down(sm) {
  //  margin-bottom: 1rem;
  //  &.row::after {
  //    display: table;
  //  }
  //  & > label {
  //    display: inline-block;
  //  }
  //  &.schema-property > div {
  //    @include make-col-ready();
  //    @include make-col(12);
  //  }
  //}
}


.row.orderline > div {
  &:not(first-child) {
    padding-left: 0;
  }
  &:first-child {
    padding-left: 15px;
  }

}
