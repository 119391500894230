.modalWrapper {
  background-color: silver;
  position: relative;
}

.modal-container {

  position: fixed;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  -moz-transform:translate(-50%,-50%);
  -ms-transform:translate(-50%,-50%);
  -webkit-transform:translate(-50%,-50%);
  -o-transform:translate(-50%,-50%);
  transition: transform 0s !important;

  //padding: 20px;

  min-width: 320px;

}
