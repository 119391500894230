
$base-color: #039be5 !default;

.icon {
  text-align: center;
  padding: 20px;
}

.title {
  text-align: center;
  padding: 10px;
  font-size: 2em;
  background-color: $base-color;
  color: white;
}

.container {
  padding: 15px;

  //box-shadow: 1px 1px 1px 1px silver;
  //border-radius: 3px;

}

.centered {
  text-align: center;
}

.error {
  margin-top: 15px;
}
