
.OrderDetails_Block_title {
  &:first-child {
    display: inline-block;
  }
}

.order-detail {
  max-width: 500px;
}
