
.pagination {
  //background-color: lighten(silver, 20%);
  //padding-bottom: 10px;
  //  margin-bottom: 10px;
  //  border-bottom: 1px solid silver;


  color: gray;
  & > div {
    display: inline-block;
    &:last-child {
      float:right;
      padding: 6px;
    }
    & > button {
      padding: 0;
      background-color: transparent;
      color: lighten(gray, 20%);
      cursor: default;
      border: 1px solid transparent;
      &:active, &:focus {
        outline: none;
      }
      &:hover:not(:disabled) {
        border: 1px solid transparent;
      }
      & > a, & > i {
        display: inline-block;
        width: 30px;
        height: 30px;
        padding: 8px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
      }
      & > a {
        color: black;
        &:hover {
          background-color: transparent;
        }
        &:active {
          color: gray;
        }
      }
    }
    & > span {
      display: inline-block;
      vertical-align: middle;
      padding-left: 4px;
      padding-right: 4px;
      & > input {
        display: inline-block;
        width: 60px;
        margin-bottom: 0;
      }
    }
  }
}
