@import "~bootstrap/scss/mixins/alert";
@import "~bootstrap/scss/mixins/border-radius";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/alert";

// hack
.alert {
  display: flex;
  flex-direction: row;
  align-items: center;

  .fa {
    margin-right: 1rem;
  }
}

.alert.layout-row .fa {
  font-size: 24px;
  margin-right: 12px;
}

.mt-4 {
  margin-top: 0.8rem;
}
