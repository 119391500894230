.SchemaField {
  .invalid-feedback {
    margin-top: 2px;
  }

  label {
    padding: 10px;
  }

  &__DatePicker {
    &__Input {
      display: flex;
      flex-direction: row;
      padding: 10px;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid silver;
      outline: none;

      .fa {
        color: gray;
        cursor: pointer;
      }
    }

    &__Value {
      flex: 1;

      & > span {
        color: gray;
      }
    }

    &__Actions {
      .fa {
        color: gray;
        margin-right: 10px;
      }
    }
  }

  .input-group.is-invalid ~ .invalid-feedback {
    display: block;
  }

  .input-group-text {
    .fa {
      cursor: pointer;
      padding: 4px;
    }
  }

  &.has-danger {
    .SchemaField__DatePicker__Input {
      border-bottom: 1px solid #d9534f;
    }
  }

  &.schema-shipmentReference .form-control-display {
    color: gray;
  }
}

.SchemaFieldArray {
  &__DeleteButton {
  }

  &__AddButton {
    cursor: pointer;
    float: right;

    .fa {
      margin-right: 0.3rem;
    }
  }

  .input-group.is-invalid ~ .invalid-feedback {
    display: block;
  }

  .input-group-text {
    .fa {
      cursor: pointer;
      padding: 4px;
    }
  }
}

.form-control {
  &-file {
    cursor: pointer;
    height: auto;
  }

  &-static {
    color: #838383;
  }
}

.FileUpload__Filename {
  color: black;
}

.VirtualizedSelectFocusedOption {
  background-color: rgba(#0288d1, 0.1);
}

.schema-field-ui-radiogroup.is-invalid ~ .invalid-feedback {
  display: block;
}

.mr-1 {
  margin-right: 0.5rem;
}
