.OrderPhotoGallery__Images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__Image {
    padding: 10px;
    border: 1px solid silver;
    position: relative;

    img {
      width: 169px;
      height: 141px;
      display: block;
      max-width: 100%;
    }

    &__Controls {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      text-align: right;
      display: none;
      align-items: center;
      justify-content: center;

      button {
        outline: 0;
        border: 0;
        margin: 0;
        padding: 0 !important;
        border-radius: 90px;
        height: 42px;
        width: 42px;
        color: black;

        &:hover {
          cursor: pointer;
        }

        .fa {
          font-size: 20px;
        }
      }
    }

    &:hover & {
      &__Controls {
        display: flex;
      }
    }
  }
}
