
$base-color: #0288d1 !default;
$box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12) !default;

.header-dropdown-menu {
  position: relative;

  display: inline-block;
  background-color: transparent;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  //padding: 0!important;
  //margin: 0!important;
  min-width: 0;
  width: auto;
  height: 48px;

  color: white;
  font-size: 20px;
  line-height: 48px;

  text-shadow: 1px 1px 1px gray;

  a {
    display: inline-block;
    color: white;
    text-decoration: none;
  }
  & > div {
    display: inline-block;
  }

  i {
    margin-left: 10px;
    margin-right: 10px;
  }

  padding-left: 5px;

  &:hover {
    //background-color: darken($base-color, 10%);
    .header-dropdown-menu-menuitems {
      display: block;
    }
  }
}

.header-dropdown-menu-menuitems {
  background-color: white;

  display: none;
  position: absolute;
  top: 5px;
  width: 100%;
  left: 0;
  list-style: none;
  color: black;
  text-shadow: none;
  margin:0;
  padding: 0;

  // border-radius: 2px;

  transform-origin: right top 0px;
  opacity: 1;
  -webkit-transition: all .4s cubic-bezier(0.25,0.8,0.25,1);
  -webkit-transition: all .4s cubic-bezier(0.25,0.8,0.25,1);
  transition: all .4s cubic-bezier(0.25,0.8,0.25,1);
  -webkit-transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;

  -webkit-box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2),0 4px 5px 0 rgba(0,0,0,0.14),0 1px 10px 0 rgba(0,0,0,0.12);
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2),0 4px 5px 0 rgba(0,0,0,0.14),0 1px 10px 0 rgba(0,0,0,0.12);

  //position: fixed;
  //left: 0;
  //top: 0;
  z-index: 100;
  -webkit-border-radius: 2px;
  border-radius: 2px;

  li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    min-height: 48px;
    height: 48px;
    -webkit-align-content: center;
    -webkit-align-content: center;
    align-content: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;

    min-height: 36px;
    height: auto!important;
    -webkit-box-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;

    color: rgba(0,0,0,0.87);

    width: 100%;
    text-transform: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    display: block;
    padding-top: 6px;
    padding-bottom: 6px;
    min-height: 36px;
    height: auto;
    margin: 0;

    padding-left: 15px;

    opacity: 1;

    -webkit-transition: all .2s cubic-bezier(0.55,0,0.55,0.2);
    -webkit-transition: all .2s cubic-bezier(0.55,0,0.55,0.2);
    transition: all .2s cubic-bezier(0.55,0,0.55,0.2);

    -webkit-transition-duration: 100ms;
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;

    -webkit-transition-delay: 10ms;
    -webkit-transition-delay: 10ms;
    transition-delay: 10ms;

    &:hover {
      cursor: pointer;
      background-color: rgba(158,158,158,0.2);
    }
  }
}

