$base-color: #039be5 !default;

.button {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  padding: 5px;
  background-color: $base-color;
  color: white;
  border: 0;
  outline: 0;
  margin-right: 5px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &[disabled] {
    opacity: 0.6;
  }
}

.large {
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 14px;
}
