//@media (max-width: 50em) {
//
//  .content .module-canvas.layout-row {
//    .layout-column:nth-child(1) {
//      flex: 0;
//      display: none;
//    }
//  }
//}

@media (min-width: 1000px) {
  .module-canvas > div > .order-detail {
    padding: 15px;
  }
}
