

.md-content {
  display: block;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}


.md-button {
  letter-spacing: .01em;
}

.md-button {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: currentColor;
  -webkit-user-select: none;
  -webkit-user-select: none;
  position: relative;
  outline: none;
  border: 0;
  display: inline-block;
  -webkit-align-items: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 6px;
  margin: 6px 8px;
  line-height: 36px;
  min-height: 36px;
  background: transparent;
  white-space: nowrap;
  min-width: 88px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: box-shadow .4s cubic-bezier(0.25,0.8,0.25,1),background-color .4s cubic-bezier(0.25,0.8,0.25,1);
  transition: box-shadow .4s cubic-bezier(0.25,0.8,0.25,1),background-color .4s cubic-bezier(0.25,0.8,0.25,1);
}

.md-button {
  padding-left: 16px;
  padding-right: 16px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.md-button:not([disabled]):hover {
  background-color: rgba(158,158,158,0.2);
}

// icons

.material-icons {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
  speak: none;
  text-decoration: none;
}

/* Rules for sizing the icon. */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

.fb-featurebar-actions-area {
  height: 40px;
  margin-bottom: 8px;
  //vertical-align: middle;
  -webkit-box-flex: 5 1 auto;
  -webkit-flex: 5 1 auto;
  flex: 5 1 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.fb-featurebar-action-buttons {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;

  color: white;
  line-height: 24px;

  -webkit-align-items: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-content: center;
  -webkit-align-content: center;
  align-content: center;

  a {
    color: white;
  }

  .material-icons {
    font-size: 32px;
    margin-left: 4px;
    margin-right: 4px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

  }
}
