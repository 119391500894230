.DashboardView {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__Widgets {
    flex: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__DisabledWidgets {
    display: flex;
    flex-direction: row;
    height: 30px;
    max-height: 30px;
    align-items: center;
    color: gray;
    text-transform: capitalize;

    &__DisabledWidget {
      &:hover {
        background-color: #f2f2f2;
      }

      padding: 6px;

      .fa {
        cursor: pointer;

        &:hover {
          color: black;
        }
      }
    }
  }
}
