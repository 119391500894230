$enable-flex: true; // flex bootstrap grids

@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/custom-forms";

// #039be5

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: #039be5;
}

.search-control {
  font-size: 20px;

  padding: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  outline: none;

  &:active,
  &:focus {
    outline: none;
  }
}

.control-label,
.col-form-label {
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.form-group {
  margin-bottom: 15px;
}

.form-control-static,
.form-control {
  padding: 10px;
  padding-left: 0;
  padding-right: 0;
  width: 100%;

  border-radius: 0;

  border: 0;
  border-bottom: 1px solid silver;

  outline: none;
}

.form-control {
  color: #55595c;
  color: #000;

  &:focus {
    color: #55595c;
    color: #000;
  }

  &:active,
  &:focus {
    outline: none;
    border-bottom: 1px solid green;
  }

  &.Select {
    padding: 0;

    .Select-control {
      border-radius: 0;
      border: 0;
      padding-left: 0;

      .Select-value,
      .Select-placeholder,
      .Select-input {
        padding-left: 0;
      }
    }
  }
}

.has-success .form-control:focus,
.has-warning .form-control:focus,
.has-danger .form-control:focus {
  box-shadow: none;
}

textarea.form-control {
  min-height: 100px;
}

.text-muted {
  color: #818a91;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.VirtualizedSelectFocusedOption {
  background-color: rgba(#0288d1, 0.1);
  .PrinterOption_MetaData {
    color: silver;
  }
}

// collapse rows in rows?
.row > div > .form-group.row {
  //margin-left: -30px;
}

//input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
//  background-color: red !important;
//  background-image: none;
//  color: rgb(0, 0, 0);
//}

.button-link {
  text-align: center;
  padding: 5px;
}

/**
 * EnableButton
 */
.EnableButton {
  text-align: center;
  background-color: #039be5;

  &.disabled {
    opacity: 0.6;

    button {
      opacity: 1;
    }
  }

  label {
    width: 40px;
    max-width: 40px;
    text-align: right;

    input {
      vertical-align: middle;
      height: 16px;
      width: 16px;
      //border: 1px solid black;
      //background-color: #e9e9e9;
      //-webkit-appearance: none;
    }
  }

  button {
    text-align: left;
  }
}

.form-control-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .form-control {
    &.form-control-checkbox {
      width: 20px;
    }
  }
}

.invalid-feedback {
  color: $brand-danger;
}

.custom-control-indicator {
  top: 0;
}
