$datalist-row-hover-color: #d8d8d8; // #ededed;
$datalist-row-odd-color: #e0e0e0; // #f9f9f9;

.datalist-fetching {
  background-color: gray;
}

.datalist-row {
  background-color: white;
  color: black;
  border: 0;
  border-top: 1px solid #eeeeee;

  min-height: 30px;
}

.datalist > div:not(.datalist-header-row):not(__react_component_tooltip) {
  &:nth-child(odd) {
    .datalist-row:not(.datalist-row-selected) {
      background-color: $datalist-row-odd-color;

      &:hover {
        background-color: $datalist-row-hover-color;
      }
    }
  }
}

.datalist-row:not(.datalist-header-row):not(.datalist-row-selected) {
  &:hover {
    background-color: $datalist-row-hover-color;
  }
}

.datalist-row:not(.datalist-header-row) {
  cursor: pointer;
}

.datalist-header-row {
  color: gray;
}

.datalist-row-selected {
  background-color: $base-color;
  color: white;
}

.datalist-col-faicon {
  min-width: 27px;
  color: $base-color;

  .fa {
    font-size: 15px;
  }
}

.datalist-row-selected .datalist-col-faicon {
  .fa {
    // fa-trash
    color: white;
  }
}

.datalist-col {
  padding: 4px;
  height: 100%;
  margin-right: 8px;

  &:first-child,
  &:last-child {
    margin-right: 0;
  }
}

.datalist-col-icon {
  min-width: 30px;
  max-width: 30px;
  text-align: center;

  img {
    max-height: 16px;
    max-width: 16px;
  }
}

.datalist-col-id {
  min-width: 90px;
  max-width: 90px;
}

.datalist-col-date {
  min-width: 74px;
  max-width: 74px;
}

.datalist-col-datetime {
  min-width: 114px;
  max-width: 114px;
  white-space: nowrap;
}

.datalist-col-status {
  min-width: 77px;
  max-width: 100px;
}

.datalist-col-price {
  min-width: 60px;
  max-width: 60px;
  margin-right: 10px;
  text-align: right;
}

.OrderList__OrderLine__CardImage {
  margin: -45px;
  margin-top: -55px;
  margin-left: -40px;
  margin-right: -75px;
}

.datalist-col .transport-icon {
  float: right;

  & > img {
    display: block;
    height: 16px;
  }

  & > .fa {
    color: black;
    display: block;
    font-size: 14px;
  }
}
