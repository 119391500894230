
.InternalLogEntry {
  // "d-flex flex-1 flex-column mb-1 p-2"
  display: flex;
  flex: 1;
  flex-direction: column;
  //margin-bottom: 0.3em;
  padding: 0.2em;

  background-color: #feffb7;
  //border: 1px solid transparent;
  &:hover {
    //border: 1px solid silver;
  }

  &__Meta {
    padding-top: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    font-size: 0.9em;
    color: gray;
    .fa {
      cursor: pointer;
      color: black;
    }
    &__TitleContainer {
      display: flex;
      flex-direction: row;
      align-content: center;
    }
    &__DateTime,
    &__User {
      font-weight: bold;
    }
  }

  &__Content {
    white-space: pre-wrap;
    word-break: normal;
    padding-top: 6px;
    padding-bottom: 6px;
  }

}
