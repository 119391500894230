
.Tag {
  background-color: $base-color;
  color: white;

  &.is-over {
    background-color: purple;
  }
  &.hasNewCount {
    background-color: red;
  }
  &.active {
    background-color: orange;
  }

  padding: 6px;

  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 10px;

  border-radius: 4px;

  & > div {
    vertical-align: middle;
    display: inline-block;
    line-height: 100%;
    margin-right: 5px;
  }

  .Tag__title {
  }

  .Tag__delete {
    margin-right: 0;
    .fa {
      cursor: pointer;
    }
  }
}

.tag-control {
  margin-bottom: 10px;
}
.tag-edit {
  &:hover {
    background-color: $base-color;
    .material-icons {
      color: white;
    }
  }
  display: inline-block;
  padding: 4px;
  padding-right: 2px;
  vertical-align: middle;
  input, div {
    vertical-align: middle;
  }
  .form-control {
    display: inline-block;
    width: inherit;
  }
  .material-icons {
    cursor: pointer;
  }
}
