.container {

  padding: 15px;

  background-color: rgb(255,255,255);
  border-radius: 2px;

  -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2),0 1px 1px 0 rgba(0,0,0,0.14),0 2px 1px -1px rgba(0,0,0,0.12);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2),0 1px 1px 0 rgba(0,0,0,0.14),0 2px 1px -1px rgba(0,0,0,0.12);

  margin-bottom: 24px;

  .title {
    margin: -15px;
    padding: 15px;
    margin-bottom: 15px;
    font-weight: bold;
    background: #039be5;
    box-shadow: 0 0 0 transparent;
    position: relative;
    transition: box-shadow .3s ease;
    z-index: 10;
    color: white;
  }

  // fails
  & > h2 {
    margin-top: 0;
  }

  h1, h2, h3 {
    margin-top: 0;
  }
}

// fails
.container > h2 {
  margin-top: 0;
}
