.container {
  position: relative;
  display: block;
  flex: 0 0 auto;
  background-color: #0288d1;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 20;
}

.appbar {
  height: 48px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.title {
}

.titleIcon {
  margin-left: 14px;
  margin-right: 12px;
}

.buttons {
  color: #fff;

  a {
    line-height: 32px;
    font-size: 32px;
    padding: 8px;
    color: white;
    display: inline-block;
  }

  //flex: 1;
  //display: -ms-flexbox;
  //display: flex;
  //-ms-flex-direction: row;
  //flex-direction: row;
  //color: #fff;
  //line-height: 24px;
  //-ms-flex-align: center;
  //align-items: center;
  //-ms-flex-line-pack: center;
  //align-content: center;
}

.rightButtons {
  float: right;
}

.langButton {
  padding: 8px;

  img {
    width: 32px;
  }
}

.titleShort {
  display: none;
}

.titleLong {
}

@media (max-width: 840px) {
  .titleShort {
    display: inline;
  }
  .titleLong {
    display: none;
  }
}

.photoService {
  min-width: auto;
}

.extra_menu {
  min-width: auto;
  cursor: pointer;
  padding-left: 10px;

  &:hover {
    background-color: darken(#0288d1, 2%);

    .header_dropdown_menu_ul {
      display: block;
    }
  }
}

.header_dropdown_menu_ul {
  display: none;
  right: 0;
  background-color: #f5f5f5;
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;

  transition: display 0.2s;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);

  li {
    font-size: 1.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;

    //background-color: #e5e5e5;
    color: #2d2d2d;
    font-weight: normal;

    white-space: pre;
    padding-left: 10px;
    padding-right: 10px;
    transition: background-color 0.2s;

    &:hover {
      background-color: #e5e5e5;
    }

    a {
      color: #2d2d2d;
    }
  }
}
