
.TabBar {
  padding: 5px;
  padding-bottom: 0;

  //& > .material-icons {
  //  display: block;
  //  vertical-align: middle;
  //  color: $base-color;
  //}

  .Tag {
    a {
      color: white;
    }
    &.active {
      text-decoration: underline;
      font-weight: bold;
      background-color: orange;
    }
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

.Tab {

  .material-icons {
    color: $base-color;
  }

  a, input {
    display: inline-block;
    padding: 6px;
    font-size: 14px;
    color: black;
    vertical-align: middle;
  }

  input, input:focus, input:active {
    outline: none;
  }

  &.active {
    a {
      font-weight: bold;
      text-decoration: underline;
      //border-bottom: 1px solid black;
    }
  }

  &.is-over {
    background-color: $base-color;
    a, .material-icons {
      color: white;
    }
  }

  &.tag-tab {
    a, input {
      margin-right: 6px;
      vertical-align: middle;
    }
    a {
      padding: 0;
    }
    input {
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
}

a.route-active {
  //border-bottom: 2px solid white;
  text-decoration: underline;
  font-weight: bold;
}
