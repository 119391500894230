
.fb-featurebar {
  background: #039be5;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  position: relative;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  z-index: 10;
}

.fb-featurebar-main {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  padding: 8px 24px 0 24px;
  min-height: 36px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.fb-featurebar-title-area {
  position: relative;
  margin-right: 16px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-flex: 99999999 1 auto;
  -webkit-flex: 99999999 1 auto;
  flex: 99999999 1 auto;
  min-width: 0;
}

.fb-featurebar-actions-area {
  -webkit-box-flex: 99999999 1 auto;
  -webkit-flex: 99999999 1 auto;
  flex: 99999999 1 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.fb-featurebar-title-area,
.fb-featurebar-actions-area {
  height: 40px;
  margin-bottom: 8px;
}


.fb-featurebar-title {
  color: white;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.container {
  -webkit-box-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;

  height: 56px;

  background: #039be5;

  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  position: relative;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  z-index: 10;

}
.title {

  display: inline-block;
  background-color: transparent!important;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  //padding: 0!important;
  //margin: 0!important;
  min-width: 0;
  width: auto;
  height: 56px;

  color: white;
  font-size: 24px;
  line-height: 56px;

  text-shadow: 1px 1px 1px gray;

  padding: 0px 24px 0 24px;

  a {
    display: inline-block;
    color: white;
    text-decoration: none;
  }

}

.rightButtons {

}

