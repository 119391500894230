@import "../../../../../../../styles/variables";

.AttachmentThumbnail {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;

  &__Image {
    border: 1px solid silver;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;

    & > img {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
    }

    margin-right: 12px;
  }

  &__Title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    flex: 2;
  }

  &__Controls {
    color: white;

    a {
      color: white;
    }

    .fa {
      margin-left: 4px;
      width: 16px;

      &:hover {
        color: white;
      }
    }
  }

  &:hover {
    background-color: $base-color;
    color: white;
    cursor: pointer;
  }

  &:hover & {
    &__Image {
      border: 1px solid $base-color;
    }

    &__Controls {
      color: white;
    }
  }

  &--isDeleting {
    color: silver;
  }

  &--isDeleting & {
    color: silver;

    &__Controls {
      color: silver;
    }
  }
}
