
.TabContainer {

  nav > label {
    padding: 6px;
    font-size: 14px;
  }

  & > section {
    padding: 0;
  }
}
