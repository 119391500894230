.WebCam__TakePhoto__Container {
  position: relative;
}

.WebCam__Stream {
  opacity: 1;
  transition: opacity 200ms;
  background-color: black;
}

.WebCam__Stream__Canvas {
  display: none;
}

.WebCam__ButtonBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  transition: transform 0s, width 200ms;

  z-index: 123;

  &__Button {
    outline: 0;
    border: 0;
    margin: 0;
    padding: 0 !important;

    border-radius: 90px;
    height: 42px;
    width: 42px;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    .fa {
      font-size: 20px;
    }

    &--accept {
      background-color: green;
      color: white;
    }

    &--take {
      background-color: #039be5;
      color: white;
    }

    &--cancel {
      background-color: red;
      color: white;
    }
  }
}


