
.action-container {

  button {
    padding: 12px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 14px;
  }
}
