/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors';
@import './variables/components';
@import './themes/default';
*/

$base-color: #039be5 !default;

a {
  color: $base-color;
  text-decoration: none;
}

a:hover,
a:active {
  text-decoration: none;
}

@import "material_design";

@import "flex";
@import "grid";
@import "alert";
@import "form";

@import "components/Header";
@import "components/TabContainer";
@import "components/TabBar";
@import "components/OrderDetails";
@import "components/EditableBlock";
@import "components/OrderAction";
@import "components/Tag";
@import "components/DataList";

.fix-width {
  //max-width: 1152px - 24px - 12px;
  width: 1116px;
  max-width: 1116px;
  min-width: 1116px;

  //@media (min-width: 1024px) {
  //  width: 1116px;
  //  max-width: 1116px;
  //  min-width: 1116px;
  //}
  @media (min-width: 1200px) {
    width: 1164px;
    max-width: 1164px;
  }
  @media (min-width: 1300px) {
    width: 1264px;
    max-width: 1264px;
  }
}

.app-modal-overlay {
  z-index: 99;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background-color: transparentize(silver, 0.6);

  @extend .flex-1;
  @extend .layout-row;
  @extend .layout-align-start-stretch;
  @extend .layout-align-center-center;

  & > div {
    //background-color: white;
    //border: 1px solid silver;

    @extend .alert;
    @extend .alert-warning;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transition: transform 0s !important;

    //padding: 20px;

    min-width: 320px;

    z-index: 100;

    font-size: 20px;
  }
}

.header-dropdown-menu {
  position: relative;
  margin-right: 15px;
  min-width: 162px;

  &:nth-child(1) {
    min-width: 136px;
  }

  &:last-child {
    min-width: initial;
  }
}

.SearchControl {
  .fa-search {
    position: absolute;
    right: 0;
    top: 13px;
    color: $base-color;
    font-size: 20px;
  }

  .fa-times {
    position: absolute;
    right: 2px;
    top: 13px;
    color: gray;
    font-size: 20px;
    display: none;
  }

  .search-control {
    border-radius: 0;
    border: 0;
  }
}

.centerized {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transition: transform 0s !important;
}

.SearchControl2 {
  color: white;

  input {
    background: transparent;
    border: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding-left: 24px;
    color: white;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: white;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: white;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: white;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: white;
    }
  }

  .fa-search {
    position: absolute;
    left: -5px;
    top: 13px;
    color: white;
    font-size: 20px;
  }

  .active,
  .active input {
    color: white;
  }
}

@import "responsive";

.big-column > .Paper {
  margin-bottom: 15px;
}

.viewport {
  height: 100%;
}

.content {
  //left: 256px;

  background-color: #eceff1;

  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &.collapsed {
    //left: 68px;
  }
}

.sub-side-bar {
  top: 0;
}

// TODO fix crappy layout fix...
.statistic-paper {
  margin-right: 12px;
  margin-bottom: 12px !important;
}

.statistics-row {
  margin-left: 12px;
  margin-right: 12px;
}

//.statistic-paper:last-child {
//  margin-right: 0;
//}
.statistic-paper:nth-child(2n) {
  //  margin-right: 0;
}

.side-bar-content {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  left: 256px;

  &.collapsed {
    left: 68px;
  }
}

.module-canvas {
  background-color: #eceff1;
  padding: 12px;
  position: relative;
}

.padding-20 {
  padding: 20px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.align-right {
  text-align: right;
}

.normal,
.normal h3 {
  font-size: 1em;
  font-weight: normal;
  color: black;
  margin: 0;
}

.red,
.red h3 {
  color: red;
}

.bold,
.bold h3 {
  font-weight: bold;
}

.green,
.green h3 {
  color: green;
}

.action-container h2 {
  margin-top: 0;
}
