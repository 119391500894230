@import "variables";
@import "base";
@import "~normalize.css/normalize";
select {
  // see https://jsfiddle.net/Lrywvy1g/1 https://github.com/twbs/bootstrap/issues/20384
  overflow: auto;
}
@import "~react-select/scss/default";
@import "~react-virtualized/styles";
@import "~react-virtualized-select/styles";
@import "~react-datetime/css/react-datetime";
@import "~react-redux-toastr/src/styles/index";

// 1200px

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.VirtualizedSelectFocusedOption {
  background-color: #0288d1 !important;
  color: white;
  cursor: pointer;
  //background-color: rgba(#0288d1,.1) !important;
}

.centered-dialog0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transition: transform 0s !important;

  img {
    padding: 20px;
  }
}

.silver {
  color: silver;
}

.small {
  font-size: 0.8rem;
}
