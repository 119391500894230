.Overlay__Loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0s !important;
  text-shadow: none;
  background-color: white;
  border: 1px solid silver;
}

.Overlay__BarCodeViewport {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-shadow: none;
  background-color: rgba(128, 128, 128, 0.8);
  z-index: 12345;

  .fa-times {
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 8px;
    margin-right: 16px;
    float: right;
    padding: 4px;
    font-size: 24px;
    cursor: pointer;
    z-index: 12345;
  }
}

.BarCodeViewport {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0s !important;

  max-width: 1024px;
  max-height: 768px;
  text-shadow: none;
  z-index: 123456;
}
