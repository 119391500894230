.MenuButton {
  position: relative;
  color: gray;
  border: 1px solid transparent;
  outline: 0;
  background: none;
  cursor: pointer;
  padding: 4px;

  &:hover {
    //border: 1px solid silver;
  }

  &__Menu {
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    box-shadow: 1px 1px 5px silver;

    &__MenuItem {
      color: black;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      padding-top: 8px;
      padding-bottom: 8px;

      &:hover {
        background-color: #eeeeee;
      }
    }
  }
}

.Widget {
  &__Title {
    margin: 0;
  }

  &__Toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
}
