.EditableBlock {
  & > h3 {
    display: flex;
    align-items:center;
  }
}

.editable {
  .edit-icon  {
    margin-left: 5px;
    color: gray;
    display: none;
    cursor: pointer;
  }
  &:hover {
    .edit-icon  {
      display: inline-block;
    }
  }
  button .fa {
    display: inline-block;
    color: white;
  }
}

.editable.editable-inline {

  &:not(.is-editing) {
    .address-streetgroup,
    .address-locationgroup {
      & > div {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  &.is-editing {

    .address-streetgroup,
    .address-locationgroup {

      @include make-row();
      margin-bottom: 15px;

      & > .form-group {
        @include flatten-row {
          @include make-col-ready();
          @include make-col(6);
          .col-xs-12 {
            margin: 0;
            padding: 0;
            width: 100%;
          }
        }
      }

      &.address-streetgroup > .form-group {
        @include make-col-ready();
        @include make-col(4);
        &:first-child {
          @include make-col-ready();
          @include make-col(8);
        }
      }

      &.address-locationgroup > .form-group {
        @include make-col-ready();
        @include make-col(8);
        &:first-child {
          @include make-col-ready();
          @include make-col(4);
        }
      }

    }

    border-radius: 2px;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2),0 1px 1px 0 rgba(0,0,0,0.14),0 2px 1px -1px rgba(0,0,0,0.12);
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2),0 1px 1px 0 rgba(0,0,0,0.14),0 2px 1px -1px rgba(0,0,0,0.12);
    margin: -5px;
    padding: 5px;
    margin-bottom: 15px;
    z-index: 9999;
  }

  .editable-buttons {
  }
}


.order-action-container h3 {
  margin: 0;
}

.close-button {
  float: right;
  .material-icons {
    cursor: pointer;
  }
}

.order-action-container h2 {
  margin: 0;
  margin-bottom: 15px;
}


.customerName {

  & > div {
    display: inline-block;
    &:not(.empty) {
      margin-right: 3px;
    }
  }
}

.is-editing {

  .customerName {
    @include make-row();
    margin-bottom: 15px;
    & > div {
      @include flatten-row {
        @include make-col-ready();
        @include make-col(3);
        .col-xs-12 {
          margin: 0;
          padding: 0;
          width: 100%;
        }
      }
      &:nth-child(2) {
        @include make-col-ready();
        @include make-col(2);
      }
      &:nth-child(4) {
        @include make-col-ready();
        @include make-col(4);
      }
    }
  }
}
